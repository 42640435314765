import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/website/website/src/mdx/templates/default/MDXDefaultLayout.jsx";
export const frontmatter = {
  title: 'Uploadcare’s HIPAA-compliant workflow',
  description: 'We’re committed to helping Uploadcare customers and users understand, and where applicable, comply with the HIPAA.',
  header: 'HIPAA-compliant workflow'
};
export const _frontmatter = {};
const layoutProps = {
  frontmatter,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`On our Enterprise plan, Uploadcare can be configured to support HIPAA-compliant workflow. Read on to learn all the details.`}</p>
    <h2 {...{
      "id": "background"
    }}><a parentName="h2" {...{
        "href": "#background"
      }}>{`Background`}</a></h2>
    <p>{`The Health Insurance Portability and Accountability Act of 1996 (HIPAA) is a U.S. federal law that requires privacy and security protections for protected health information (PHI). If you’re a covered entity or business associate subject to HIPAA, Uploadcare can be configured to support PHI within secure uploading, storing, processing, and delivering files.`}</p>
    <p>{`When a covered entity or business associate has executed a Business Associate Agreement with Uploadcare, Uploadcare is deemed a business associate.`}</p>
    <h2 {...{
      "id": "requirements-and-limitations"
    }}><a parentName="h2" {...{
        "href": "#requirements-and-limitations"
      }}>{`Requirements and limitations`}</a></h2>
    <p>{`Before Uploadcare can support your HIPAA compliance, you must review and agree to implement the guidelines in our Requirements for HIPAA Entities. Please note the following:`}</p>
    <ul>
      <li parentName="ul">{`You must be using the Uploadcare Enterprise plan.`}</li>
      <li parentName="ul">{`You must execute a Business Associate Agreement.`}</li>
      <li parentName="ul">{`You must authorize delivery of uploaded files with Signed URLs for your HIPAA compliant Uploadcare accounts.`}</li>
      <li parentName="ul">{`You must use two-factor authentication for anybody who has access to your Uploadcare account.`}</li>
      <li parentName="ul">{`You must not use Video Processing or Document Conversion features with a HIPAA compliant Uploadcare accounts.`}</li>
      <li parentName="ul">{`You must configure a backup.`}</li>
    </ul>
    <p>{`Check out our `}<a parentName="p" {...{
        "href": "/docs/guides/hipaa/"
      }}>{`HIPAA guide`}</a>{`.`}</p>
    <h2 {...{
      "id": "request-more-information"
    }}><a parentName="h2" {...{
        "href": "#request-more-information"
      }}>{`Request more information`}</a></h2>
    <p>{`If you’d like to learn more about Uploadcare and HIPAA, `}<a parentName="p" {...{
        "href": "mailto:sales@uploadcare.com"
      }}>{`get in touch`}</a>{` with us. We’re happy to provide you with more information.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      